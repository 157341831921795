import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: config.BASE_URL }),
  reducerPath: "api",
  endpoints: (build) => ({
    login: build.mutation({
      query: (credentials) => ({
        url: "dashboard/authenticate",
        method: "POST",
        body: { ...credentials },
        headers: {
          "x-election": config.requestHeader,
        },
      }),
    }),
    getStatistics: build.query({
      query: (type) => ({
        url: `dashboard/list-statistics?type=${type}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          "x-election": config.requestHeader,
        },
      }),
    }),
    getSpecialData: build.query({
      query: () => ({
        url: `dashboard/get-special-data`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
          "x-election": config.requestHeader,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetStatisticsQuery,
  useGetSpecialDataQuery,
} = api;

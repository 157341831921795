import { Box, Button, useTheme } from "@mui/material";
import Header from "../../components/Header";
import StatisticsDataGrid from "../../components/StatisticsDataGrid";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useAppDispatch } from "../../hooks";
import { useGetStatisticsQuery } from "../../state/api";
import { useEffect } from "react";
import { makeLogout, Statistics } from "../../state";

const StatisticsInfo = () => {
  const theme: any = useTheme();
  const dispatch = useAppDispatch();
  const { data, isLoading, isError, error, refetch }: any =
    useGetStatisticsQuery("all");
  const statistics: Statistics[] = data ? data.statistics : [];

  useEffect(() => {
    if (isError && error && error.originalStatus === 403) {
      dispatch(makeLogout());
    }
  });

  const handleRefresh = () => {
    refetch();
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        sx={{
          display: "flex",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Header title="STATISTICS" subtitle="Entire list of statistics" />
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          size="medium"
          onClick={handleRefresh}
          color="secondary"
        >
          Refresh
        </Button>
      </Box>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "5rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <StatisticsDataGrid
          isLoading={isLoading}
          statistics={statistics}
          data={data}
        />
      </Box>
    </Box>
  );
};

export default StatisticsInfo;

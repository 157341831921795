import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "../../components/LoginForm";

const Login = () => {
  const theme: any = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        width="100%"
        // sx={{ backgroundColor: colors.grey[100] }}
        p="1rem 6%"
        textAlign="center"
      >
        <Typography
          fontWeight="bold"
          fontSize="32px"
          color={theme.palette.secondary[100]}
        >
          Election Wars Dashboard
        </Typography>
      </Box>

      <Box
        width={isNonMobileScreens ? "30%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        sx={{ backgroundColor: theme.palette.background.alt }}
      >
        <Typography
          fontWeight="500"
          variant="h5"
          sx={{ mb: "1.5rem" }}
          color={theme.palette.secondary[300]}
        >
          Welcome to Election Wars!
        </Typography>
        <Form />
      </Box>
    </Box>
  );
};

export default Login;

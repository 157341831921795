export const formatNumber = (
  num: number | string | null | undefined
): string => {
  // Check for nullish values and return a default dash if null or undefined
  if (num === null || num === undefined) {
    return "-";
  }

  // If it's a number, format it with the correct locale (en-US)
  if (typeof num === "number") {
    return num.toLocaleString("en-US");
  }

  // Try to parse it as a float in case it's a numeric string, and format it
  const parsed = parseFloat(num);
  if (!isNaN(parsed)) {
    return parsed.toLocaleString("en-US");
  }

  // Return the input as-is if it's not numeric
  return num;
};

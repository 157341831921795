import {
  Box,
  Button,
  TextField,
  Typography,
  colors,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { setAdmin } from "../state";
import { useLoginMutation } from "../state/api";
import React from "react";
import { useAppDispatch } from "../hooks";

const loginSchema = yup.object().shape({
  user: yup.string().required("required"),
  password: yup.string().required("required"),
});

const initialValuesLogin: { user: string; password: string } = {
  user: "",
  password: "",
};

const LoginForm = () => {
  const theme: any = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [serverErrorMessage, setServerErrorMessage] = React.useState("");

  const [loginMutation] = useLoginMutation({});

  const handleFormSubmit = async (values: any, onSubmitProps: any) => {
    try {
      const { data }: any = await loginMutation(values);
      onSubmitProps.resetForm();

      if (data.status === "success") {
        dispatch(
          setAdmin({
            user: data.user,
            token: data.token,
          })
        );
        navigate("/");
      } else {
        setServerErrorMessage(data.error);
      }
    } catch (e: any) {
      setServerErrorMessage(e.value);
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              variant="filled"
              label="Admin User"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.user}
              name="user"
              error={Boolean(touched.user) && Boolean(errors.user)}
              helperText={touched.user && errors.user}
              sx={{ gridColumn: "span 4" }}
              InputLabelProps={{
                style: { color: theme.palette.secondary[300] },
                shrink: true,
                sx: { fontSize: "16px" }, // Adjust the font size here
              }}
            />

            <TextField
              variant="filled"
              label="Password"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: "span 4" }}
              InputLabelProps={{
                style: { color: theme.palette.secondary[300] },
                shrink: true,
                sx: { fontSize: "16px" }, // Adjust the font size here
              }}
            />
          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: theme.palette.secondary[300],
                color: theme.palette.primary[500],
                "&:hover": { backgroundColor: theme.palette.secondary[200] },
              }}
            >
              LOGIN
            </Button>
            <Typography
              variant="h6"
              sx={{ color: colors.red, alignSelf: "center" }}
            >
              {serverErrorMessage}
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;

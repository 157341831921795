import { DataGrid } from "@mui/x-data-grid";

import DataGridCustomToolbar from "./DataGridCustomToolbar";
import { Statistics } from "../state";

interface StatisticsDataGridProps {
  isLoading: boolean;
  statistics: Statistics[];
  data: any;
}

const StatisticsDataGrid = ({
  isLoading,
  statistics,
  data,
}: StatisticsDataGridProps) => {
  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   flex: 1,
    // },
    {
      field: "accountsCreated",
      headerName: "AccountsCreated",
      flex: 1,
    },
    {
      field: "usersLogged",
      headerName: "UsersLogged",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      flex: 1,
    },
  ];

  return (
    <DataGrid
      loading={isLoading || !data || !data.statistics}
      rows={statistics}
      columns={columns}
      pageSizeOptions={[20, 50, 100]}
      pagination
      slots={{ toolbar: DataGridCustomToolbar }}
    />
  );
};

export default StatisticsDataGrid;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface GlobalState {
  mode: "light" | "dark";
  admin: string | null;
  token: string | null;
}

const initialState: GlobalState = {
  mode: "dark",
  admin: null,
  token: null,
};

export interface Statistics {
  id: number;
  usersLogged: number;
  accountsCreated: number;
  type: string;

  createdAt: Date;
}

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setAdmin: (
      state,
      action: PayloadAction<{ user: string; token: string }>
    ) => {
      localStorage.setItem("token", action.payload.token);
      state.admin = action.payload.user;
      state.token = action.payload.token;
    },
    makeLogout: (state) => {
      state.admin = null;
      state.token = null;
      localStorage.clear();
    },
  },
});

export const { setMode, setAdmin, makeLogout } = globalSlice.actions;

export default globalSlice.reducer;

import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "./theme";
import Layout from "./scenes/layout";
import Dashboard from "./scenes/dashboard";
import Daily from "./scenes/daily";
import Monthly from "./scenes/monthly";
import Login from "./scenes/login";
import { RootState } from ".";
import { useAppSelector } from "./hooks";
import StatisticsInfo from "./scenes/statistics";

function App() {
  const mode = useAppSelector(
    (state: RootState) => state.global.mode as "dark" | "light"
  );
  const isAuth = Boolean(
    useAppSelector((state: RootState) => state.global.token)
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  isAuth ? (
                    <Navigate to="/dashboard" replace />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/dashboard"
                element={isAuth ? <Dashboard /> : <Navigate to="/login" />}
              />
              <Route
                path="/statistics"
                element={isAuth ? <StatisticsInfo /> : <Navigate to="/login" />}
              />
              <Route
                path="/daily"
                element={isAuth ? <Daily /> : <Navigate to="/login" />}
              />
              <Route
                path="/monthly"
                element={isAuth ? <Monthly /> : <Navigate to="/login" />}
              />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

import Header from "../../components/Header";
import { PointOfSale, PersonAdd } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  useTheme,
  useMediaQuery,
  Button,
  Typography,
} from "@mui/material";
import { useGetSpecialDataQuery, useGetStatisticsQuery } from "../../state/api";
import StatBox from "../../components/StatBox";
import { useAppDispatch } from "../../hooks";
import { useEffect, useMemo } from "react";
import { makeLogout } from "../../state";
import { DateTime } from "luxon";
import { formatNumber } from "../../utils";

const Dashboard = () => {
  const theme: any = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const dispatch = useAppDispatch();

  const {
    data: totalData,
    isError: isTotalError,
    error: totalError,
    refetch: refetchTotalData,
  }: any = useGetStatisticsQuery("total");

  const {
    data: dayData,
    isError: isDayError,
    error: dayError,
    refetch: refetchDayData,
  }: any = useGetStatisticsQuery("day");

  const {
    data: specialData,
    isError: isSpecialError,
    error: specialError,
    refetch: refetchSpecialData,
  }: any = useGetSpecialDataQuery(undefined);

  useEffect(() => {
    if (
      (isTotalError && totalError.originalStatus === 403) ||
      (isDayError && dayError.originalStatus === 403) ||
      (isSpecialError && specialError.originalStatus === 403)
    ) {
      dispatch(makeLogout());
    }
  });

  console.log(specialData);

  const handleRefresh = () => {
    refetchTotalData(); // Refetch the total data
    refetchDayData(); // Refetch the daily data
    refetchSpecialData();
  };

  // Calculate average logins per day
  const averageLoginsPerDay = useMemo(() => {
    if (!dayData || !dayData.statistics || dayData.statistics.length === 0) {
      return 0;
    }

    const dailyData = dayData.statistics;

    const totalLogins = dailyData.reduce(
      (sum: number, statistic: any) => sum + Number(statistic.usersLogged),
      0
    );
    const numberOfDays = dailyData.length;

    return numberOfDays > 0
      ? parseFloat((totalLogins / numberOfDays).toFixed(2))
      : 0;
  }, [dayData]);

  // Calculate logins today
  const loginsToday = useMemo(() => {
    if (!dayData) return 0;

    const dailyData = dayData.statistics;

    const today = DateTime.utc().startOf("day");

    const todayStats = dailyData.find((statistic: any) =>
      DateTime.fromISO(statistic.createdAt).toUTC().hasSame(today, "day")
    );

    return todayStats ? todayStats.usersLogged : 0;
  }, [dayData]);

  // Calculate average creations per day
  const averageCreationsPerDay = useMemo(() => {
    if (!dayData || !dayData.statistics || dayData.statistics.length === 0) {
      return 0;
    }

    const dailyData = dayData.statistics;

    const totalLogins = dailyData.reduce(
      (sum: number, statistic: any) => sum + Number(statistic.accountsCreated),
      0
    );
    const numberOfDays = dailyData.length;

    return numberOfDays > 0
      ? parseFloat((totalLogins / numberOfDays).toFixed(2))
      : 0;
  }, [dayData]);

  // Calculate creations today
  const accountCreatedToday = useMemo(() => {
    if (!dayData) return 0;

    const dailyData = dayData.statistics;

    const today = DateTime.utc().startOf("day");

    const todayStats = dailyData.find((statistic: any) =>
      DateTime.fromISO(statistic.createdAt).toUTC().hasSame(today, "day")
    );

    return todayStats ? todayStats.accountsCreated : 0;
  }, [dayData]);

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        sx={{
          display: "flex",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          size="medium"
          onClick={handleRefresh}
          color="secondary"
        >
          Refresh
        </Button>
      </Box>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 8" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="Accounts Created Total"
          value={
            totalData && formatNumber(totalData.statistics[0].accountsCreated)
          }
          increase=""
          description=""
          icon={
            <PersonAdd
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Accounts Created Today"
          value={formatNumber(accountCreatedToday)}
          increase=""
          description=""
          icon={
            <PersonAdd
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Average Creations Accounts Per Day"
          value={`${formatNumber(averageCreationsPerDay)}`}
          increase=""
          description=""
          icon={
            <PersonAdd
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Special Users Count"
          value={specialData && formatNumber(specialData.specialUserCount)}
          increase=""
          description=""
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
      </Box>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 8" },
        }}
      >
        {/* ROW 2 */}

        <StatBox
          title="Users Logged Total"
          value={totalData && formatNumber(totalData.statistics[0].usersLogged)}
          increase=""
          description=""
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Users Logged Today"
          value={formatNumber(loginsToday)}
          increase=""
          description=""
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Average Loggins Per Day"
          value={`${formatNumber(averageLoginsPerDay)}`}
          increase=""
          description=""
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
      </Box>

      {/* Subtitle for ROW 3 */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Partners Accounts Information
      </Typography>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 8" },
        }}
      >
        {/* ROW 3 */}

        <StatBox
          title="White Bunny Accounts Created"
          value={specialData && formatNumber(specialData.whiteBunnyUserCount)}
          increase=""
          description=""
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Pigs Accounts Created"
          value={specialData && formatNumber(specialData.pigsUserCount)}
          increase=""
          description=""
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
